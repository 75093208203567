import SearchForm from "@/components/SearchForm";
import state from "@/state";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Search, X } from "react-feather";

const SearchPopover = () => {
  return (
    <Popover className="SearchPopover">
      {({ open, close }) => {
        // Set the global state to the open state of the menu
        state.search.isOpen = open;

        return (
          <>
            {open && <ScrollListener close={close} />}
            <Popover.Button
              className={clsx(
                "SearchPopover__button",
                "flex h-10 w-10 items-center justify-center rounded-full border-transparent bg-sky-500 text-white !outline-none transition duration-300 ease-out hover:bg-gray-900"
              )}
              aria-label="Search"
            >
              <Search
                className={clsx("SearchPopover__icon", "h-5 w-5")}
                strokeWidth={2}
              />
            </Popover.Button>

            <Transition
              show={open}
              enter="transition duration-300 ease-in-out"
              enterFrom="transform opacity-0 -translate-y-full"
              enterTo="transform opacity-100 translate-y-0"
              leave="transition duration-300 ease-in-out"
              leaveFrom="transform opacity-100 translate-y-0"
              leaveTo="transform opacity-0 -translate-y-full"
              className={clsx(
                "SearchPopover__content",
                "fixed inset-x-0 top-0 z-50 h-32 bg-gray-900 lg:h-36"
              )}
            >
              <Popover.Panel
                className={clsx(
                  "SearchPopover__panel",
                  "flex h-full items-center justify-center"
                )}
              >
                <Transition.Child
                  enter="transition duration-500 ease-in-out delay-100"
                  enterFrom="transform opacity-0 -translate-y-2"
                  enterTo="transform opacity-100 translate-y-0"
                  leave="transition duration-500 ease-in-out"
                  leaveFrom="transform opacity-100 translate-y-0"
                  leaveTo="transform opacity-0 -translate-y-2"
                  className={clsx("relative flex w-full")}
                >
                  <div className={clsx("container w-full lg:pr-28")}>
                    <div className={clsx("relative")}>
                      <div className={clsx("flex items-center space-x-4")}>
                        <div className={clsx("flex-grow text-gray-950")}>
                          <SearchForm open={open} close={close} />
                        </div>
                      </div>
                      <button
                        className={clsx(
                          "SearchPopover__close",
                          "absolute right-2 top-1/2 -mt-5 flex h-10 w-10 items-center justify-center rounded-full border-transparent bg-gray-300 text-gray-900 !outline-none transition duration-300 ease-out hover:bg-gray-400 lg:right-0 lg:bg-gray-800 lg:text-white lg:hover:bg-gray-900"
                        )}
                        onClick={() => close()}
                      >
                        <X className={clsx("w-5")} strokeWidth={1.5} />
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

function ScrollListener({ close }) {
  const initialPositionRef = useRef(window.pageYOffset);

  useEffect(() => {
    function handleScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition - initialPositionRef.current > 15) {
        close();
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [close, initialPositionRef]);

  return null;
}

export default SearchPopover;
