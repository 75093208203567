import AuthMenuPopover from "@/components/AuthMenuPopover";
import Logo from "@/components/Logo";
import Logotype from "@/components/Logotype";
import MenuToggle from "@/components/MenuToggle";
import PrimaryNavigation from "@/components/PrimaryNavigation";
import SearchPopover from "@/components/SearchPopover";
import SiteLogo from "@/components/SiteLogo";
import SiteSelector from "@/components/SiteSelector";
import useScrollSpy from "@/hooks/useScrollSpy";
import state from "@/state";
import clsx from "clsx";
import { useSession } from "next-auth/react";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";

const Header = ({
  primaryNavigation,
  site = null,
  siteLogo = null,
  hideBorder = false,
  hideYcnBranding = false,
}: {
  primaryNavigation: any;
  site?: string | null;
  siteLogo?: {
    dark: any;
    light: any;
  } | null;
  hideBorder?: boolean;
  hideYcnBranding?: boolean;
}) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const { data: session } = useSession();

  // TODO: remove workaround for kfcmentors
  const showAuthMenu = !(
    site === "kfcmentors" ||
    site?.includes("pekfcacademy") ||
    site === "themodernhouse"
  );

  const stateSnapshot = useSnapshot(state);

  const isClientSite = site !== "default";

  // Identify if this is a kfc academy site
  const isKfcAcademy = site?.includes("pekfcacademy");

  const showSiteLogo =
    isClientSite &&
    !!siteLogo &&
    (!!siteLogo.dark?.url || !!siteLogo.light?.url);

  const showSearch = isClientSite && !site?.includes("pekfcacademy");
  const showLogotype = stateSnapshot.header.isTop || isTransitioning;
  const showLogo = !stateSnapshot.header.isTop && !isTransitioning;

  // When we navigate away from the page, we need to set the header theme to light
  useEffect(() => {
    return () => {
      state.header.theme = "dark";
    };
  }, []);

  return (
    <>
      <header
        className={clsx(
          "Header",
          "fixed left-0 right-0 top-0 z-30 flex duration-300 ease-out",
          {
            "translate-y-0": stateSnapshot.header.isTop,
            "bg-white bg-opacity-[0.975] shadow-sm backdrop-blur-sm":
              !stateSnapshot.header.isTop,
          },
        )}
      >
        <div
          className={clsx(
            "Header__container",
            "flex w-full items-center transition-all duration-300 ease-out lg:px-16",
            {
              "h-16 lg:h-28": stateSnapshot.header.isTop,
              "h-16 lg:h-20": !stateSnapshot.header.isTop,
              "text-white border-b-white/20":
                stateSnapshot.header.isTop &&
                stateSnapshot.header.theme === "light",
              "text-gray-900": !stateSnapshot.header.isTop,
              "border-b": !hideBorder,
              "border-b-transparent": !stateSnapshot.header.isTop,
            },
          )}
        >
          <div
            className={clsx(
              "Header__wrapper",
              "relative flex h-full w-full items-center max-lg:px-6 ",
            )}
          >
            {!hideYcnBranding && (
              <Link
                href="/"
                className={clsx(
                  "Header__logotype",
                  "flex items-center justify-start",
                  {
                    "h-5 w-auto lg:h-6": showLogotype,
                    "h-10 w-10 lg:h-12 lg:w-12": showLogo,
                  },
                )}
              >
                {showLogotype && <Logotype />}
                {showLogo && <Logo fill={"#ffffff"} />}
              </Link>
            )}

            {showSiteLogo && (
              <Link
                href="/"
                className={clsx(
                  "Header__siteLogo",
                  "flex-shrink-0 flex-grow items-center xl:flex",
                  {
                    "hidden ml-4 border-l pl-4 lg:ml-5 lg:pl-5":
                      !hideYcnBranding,
                    "text-white": stateSnapshot.header.theme === "light",
                    "text-gray-900":
                      stateSnapshot.header.theme === "dark" ||
                      !stateSnapshot.header.isTop,
                  },
                )}
              >
                <SiteLogo
                  darkLogo={siteLogo.dark}
                  lightLogo={siteLogo.light}
                  enlargedHeight={
                    hideYcnBranding && site?.includes("pekfcacademy")
                  }
                />
              </Link>
            )}

            <div className={clsx("Header__content", "ml-auto flex")}>
              {!!primaryNavigation && (
                <div
                  className={clsx(
                    "Header__primaryNavigation",
                    "hidden lg:flex",
                    {
                      "border-r-white/20":
                        stateSnapshot.header.theme === "light" &&
                        stateSnapshot.header.isTop,
                      "border-r pr-3 mr-3 lg:mr-4 lg:pr-4":
                        showAuthMenu || showSearch,
                    },
                  )}
                >
                  <PrimaryNavigation
                    primaryNavigation={primaryNavigation}
                    exclude={["Privacy Policy"]}
                  />
                </div>
              )}

              <div
                className={clsx(
                  "Header__toolbar",
                  "flex items-center space-x-2",
                )}
              >
                {isClientSite && isKfcAcademy && (
                  <div className={clsx("ml-4 2xl:ml-5")}>
                    <SiteSelector />
                  </div>
                )}
                {isClientSite && !session && showAuthMenu && (
                  <div className={clsx("hidden sm:block")}>
                    <Link
                      href="/auth/signup"
                      className={clsx(
                        "flex h-10 items-center whitespace-nowrap rounded-full bg-gray-900 px-6 font-medium text-white transition-opacity duration-300 ease-out hover:opacity-75",
                      )}
                    >
                      Set up your Login
                    </Link>
                  </div>
                )}
                {showAuthMenu && (
                  <div
                    className={clsx("Header__authMenu", "flex items-center")}
                  >
                    <AuthMenuPopover />
                  </div>
                )}
                {showSearch && (
                  <div
                    className={clsx(
                      "Header__searchToggle",
                      "flex items-center",
                    )}
                  >
                    <SearchPopover />
                  </div>
                )}

                <div
                  className={clsx(
                    "Header__menuToggle",
                    "flex items-center lg:hidden",
                  )}
                >
                  <MenuToggle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ScrollSpy />
    </>
  );
};

const ScrollSpy = () => {
  useScrollSpy();

  return null;
};

export default Header;
